

import { Studio } from "./types";

const Studios: Array<Studio> = [
    {
        name: "渋谷",
        color: "#e51572",
        brightColor: "#ffe1f1",
        nameLine1: null,
        nameLine2: null,
        nameLine1SmartPhone: null,
        nameLine2SmartPhone: null,
    },
    {
        name: "六本木",
        color: "#a55fa5",
        brightColor: "#e8def4",
        nameLine1: null,
        nameLine2: null,
        nameLine1SmartPhone: null,
        nameLine2SmartPhone: null,
    },
    {
        name: "青山",
        color: "#009bdc",
        brightColor: "#daf0fd",
        nameLine1: null,
        nameLine2: null,
        nameLine1SmartPhone: null,
        nameLine2SmartPhone: null,
    },
    {
        name: "新宿",
        color: "#f59600",
        brightColor: "#ffeed0",
        nameLine1: null,
        nameLine2: null,
        nameLine1SmartPhone: null,
        nameLine2SmartPhone: null,
    },
    {
        name: "芝公園 A st.",
        shortName: "芝公園A",
        color: "#418e24",
        brightColor: "#eaf5d7",
        nameLine1: "芝公園",
        nameLine2: "A st.",
        nameLine1SmartPhone: "芝公",
        nameLine2SmartPhone: "園A",
    },
    {
        name: "芝公園 B st.",
        shortName: "芝公園B",
        color: "#a57d50",
        brightColor: "#ede1d5",
        nameLine1: "芝公園",
        nameLine2: "B st.",
        nameLine1SmartPhone: "芝公",
        nameLine2SmartPhone: "園B",
    }
];

export default Studios;