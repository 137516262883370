import {
  SchedulerExistingEvent
} from "../../types";

function process_events(events_in: Array<SchedulerExistingEvent>, weekStart: Date, sort: boolean = false) {
  const events = (sort ? events_in.sort((a: SchedulerExistingEvent, b: SchedulerExistingEvent) =>
    a.from.getTime() - b.from.getTime()) : events_in);

  const out: Array<SchedulerExistingEvent> = [];
  events.forEach((evt: SchedulerExistingEvent) => {
    out.push({
      ...evt,
      original: evt,
    });
  });
  return out;
}

export default process_events;
