import React from "react";
import Calendar from "./Calendar";
import "./AppCalendar.css";
import { GlobalState } from "../GlobalState";
import { isMobile } from "react-device-detect";

type Props = {
  globalState: GlobalState;
  hasScheduler: boolean;
};

const AppCalendar: React.FC<Props> = ({ globalState, hasScheduler }) => {
  const selected = React.useSyncExternalStore((onStoreChange: () => void) => globalState.subscribe(onStoreChange), () => globalState.getSelectedDate());
  const setSelected = (newDate: Date) => {
    if (hasScheduler) {
      globalState.setSelectedDate(newDate);
    } else {
      // schedulerページに移動
      const pad2 = (n: number) =>  { return n < 10 ? '0' + n : n };
      const dateStr = newDate.getFullYear().toString() + pad2(newDate.getMonth() + 1) + pad2(newDate.getDate());
      if (isMobile) {
        window.location.href = 'http://lensman.jp/sp/reservation/schedule.php?date=' + dateStr;
      } else {
        window.location.href = 'http://lensman.jp/reservation/schedule.php?date=' + dateStr;
      }
    }
  };

  return (
    <div className="AppCalendar">
      <Calendar selected={selected} setSelected={setSelected} isMobile={isMobile} />
    </div>
  );
}

export default AppCalendar;
