import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppCalendar from './components/AppCalendar';
import AppScheduler from './components/AppScheduler';
import reportWebVitals from './reportWebVitals';
import { getReservations } from "./reservations";
import { GlobalState } from "./GlobalState";

const globalState = new GlobalState();
const params = (new URL(document.location.toString())).searchParams;
const dateParam = params.get("date");
if (dateParam) {
  const year = parseInt(dateParam.substring(0, 4));
  const month = parseInt(dateParam.substring(4, 6));
  const day = parseInt(dateParam.substring(6, 8));
  const parsedDateParam = new Date(year, month - 1, day);

  globalState.setSelectedDate(parsedDateParam);
}

const calendarElement = document.getElementById('reservation_calendar') as HTMLElement;
const schedulerElement = document.getElementById('reservation_scheduler') as HTMLElement;

const calendar = ReactDOM.createRoot(calendarElement);
calendar.render(
  <React.StrictMode>
    <AppCalendar globalState={globalState} hasScheduler={!!schedulerElement} />
  </React.StrictMode>
);

if (schedulerElement) {
  (async() => {
    const fetchedEvents = await getReservations();
    if (fetchedEvents) {
      globalState.setEvents(fetchedEvents);
    } else {
      alert("予約状況が取得できません。")
    }
  })();

  const scheduler = ReactDOM.createRoot(schedulerElement);
  scheduler.render(
    <React.StrictMode>
      <AppScheduler globalState={globalState} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
