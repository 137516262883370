import React from "react";
import "./AppScheduler.css";
import Scheduler from "./Scheduler";
import { GlobalState } from "../GlobalState";
import studios from "../studios";
import { Studio } from "../types";
import { isMobile } from "react-device-detect";

type StudioButtonProps = {
  index: number | null;
  studio: Studio | null;
  setStudioIndex: React.Dispatch<React.SetStateAction<number | null>>;
};

const StudioButton: React.FC<StudioButtonProps> = ({ index, studio, setStudioIndex }) => {
  const onClickStudioButton = (e: React.MouseEvent<HTMLElement>) => {
    setStudioIndex(index);
  };

  if (index === null || studio === null) {
    return (
      <div className="studio_button" onClick={onClickStudioButton}>
        <div className={ isMobile ? "shortNameSmartPhone" : "shortName" }>
          全て
        </div>
      </div>
    );
  }

  if (studio.nameLine1 === null || studio.nameLine2 === null) {
    return (
      <div style={{ color: studio.color, borderColor: studio.color }} className="studio_button" onClick={onClickStudioButton}>
        <div className={ isMobile ? "shortNameSmartPhone" : "shortName" }>
          {studio.name}
        </div>
        { !isMobile &&
        <div className="weekly">
          週間表示
        </div>
        }
      </div>
    );  
  } else if (isMobile) {
    return (
      <div style={{ color: studio.color, borderColor: studio.color }} className="studio_button" onClick={onClickStudioButton}>
        <div className="nameLine1SmartPhone">
          {studio.nameLine1SmartPhone}
        </div>
        <div className="nameLine2SmartPhone">
          {studio.nameLine2SmartPhone}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ color: studio.color, borderColor: studio.color }} className="studio_button" onClick={onClickStudioButton}>
        <div className="nameLine1">
          {studio.nameLine1}
        </div>
        <div className="nameLine2">
          {studio.nameLine2}
        </div>
        <div className="weekly">
          週間表示
        </div>
      </div>
    );
  }
};

type Props = {
  globalState: GlobalState;
};

const AppScheduler: React.FC<Props> = ({ globalState }) => {
  const events = React.useSyncExternalStore((onStoreChange: () => void) => globalState.subscribe(onStoreChange), () => globalState.getEvents());
  const selected = React.useSyncExternalStore((onStoreChange: () => void) => globalState.subscribe(onStoreChange), () => globalState.getSelectedDate());
  const setSelected = (newDate: Date) => {
    globalState.setSelectedDate(newDate);
  };
  const [studioIndex, setStudioIndex] = React.useState<number | null>(null);

  if (!events) {
    return <div>カレンダー情報取得中</div>;
  }

  return (
    <div className="AppScheduler">
      <div className="studio_selector">
        <StudioButton key={-1} index={null} studio={null} setStudioIndex={setStudioIndex} />
        {studios.map((studio, i) => (
          <StudioButton key={i} index={i} studio={studio} setStudioIndex={setStudioIndex} />
        ))}
      </div>

      <Scheduler
        events={events}
        selected={selected}
        setSelected={setSelected}
        studioIndex={studioIndex}
        offsetHours={8}
        isMobile={isMobile}
      />
    </div>
  );
};

export default AppScheduler;