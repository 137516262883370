const STROKE_COLOR = "red";
const STROKE_WIDTH = 2;

export const LessThanSymbol = () => {
  return (
    <svg
      width="8"
      height="18"
      viewBox="0 0 8 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(0 4)"
    >
      <line
        x1="7.35337"
        y1="0.353553"
        x2="0.353371"
        y2="7.35355"
        stroke={STROKE_COLOR}
        strokeWidth={STROKE_WIDTH}
      />
      <line
        x1="0.353569"
        y1="6.64645"
        x2="7.35357"
        y2="13.6464"
        stroke={STROKE_COLOR}
        strokeWidth={STROKE_WIDTH}
      />
    </svg>
  );
};

export const MoreThanSymbol = () => {
  return (
    <svg
      width="8"
      height="18"
      viewBox="0 0 8 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(0 4)"
    >
      <line
        x1="7.35337"
        y1="0.353553"
        x2="0.353371"
        y2="7.35355"
        stroke={STROKE_COLOR}
        strokeWidth={STROKE_WIDTH}
      />
      <line
        x1="0.353569"
        y1="6.64645"
        x2="7.35357"
        y2="13.6464"
        stroke={STROKE_COLOR}
        strokeWidth={STROKE_WIDTH}
      />
    </svg>
  );
};
