import React, { useState, useEffect, useRef, useMemo } from "react";
import DATE_UTILS from "../../date";
import process_events from "./process";
import studios from "../../studios";
import { DateFormatter, DateRangeFormatter } from "../DateFormatter";
import {
  SchedulerRectangle,
  SchedulerEvent,
  SchedulerStyles,
  SchedulerProps,
  EventProps,
} from "../../types";
import "./Scheduler.scss";
import { LessThanSymbol, MoreThanSymbol } from "../Icons";

function pos_from_date(
  date: Date,
  eventSizeRef: React.MutableRefObject<HTMLTableCellElement>,
  headerRef: React.MutableRefObject<HTMLTableCellElement>,
  index: number,
  offsetHours: number,
) {
  if (!eventSizeRef.current || !headerRef.current) return { x: 0, y: 0 };

  const rect: SchedulerRectangle = eventSizeRef.current.getBoundingClientRect();
  const header: SchedulerRectangle = headerRef.current.getBoundingClientRect();

  const rectHeightWithoutBorder = rect.height - 1; // border 1px

  const x: number = index * rect.width + header.width;
  const y: number =
    (date.getHours() - offsetHours) * rectHeightWithoutBorder +
    (date.getMinutes() / 60) * rectHeightWithoutBorder +
    header.height + 0.5;

  if (Number.isNaN(x) || Number.isNaN(y)) return { x: 0, y: 0 };

  return { x, y };
}

function styles_from_event(
  event: SchedulerEvent,
  eventSizeRef: React.MutableRefObject<HTMLTableCellElement>,
  headerRef: React.MutableRefObject<HTMLTableCellElement>,
  isWeekly: boolean,
  offsetHours: number,
): React.CSSProperties {
  let { from, to, studioIndex, style }: SchedulerEvent = event;

  if (!from || !to) return {};

  if (from > to) {
    let tmp = from;
    from = to;
    to = tmp;
  }

  const rect = (
    eventSizeRef.current ?? {
      getBoundingClientRect: () => ({
        width: window.innerWidth * 0.1,
        height: window.innerHeight * 0.1,
      }),
    }
  ).getBoundingClientRect();

  const pos = pos_from_date(
    from,
    eventSizeRef,
    headerRef,
    isWeekly ? from.getDay() : studioIndex,
    offsetHours,
  );
  const dif = DATE_UTILS.difference(to, from);
  if (dif === 0) return {};

  const rectHeightWithoutBorder = rect.height - 1;

  const out: React.CSSProperties = {
    top: `${pos.y - 0.5}px`,
    height: `${Math.floor((dif / DATE_UTILS.HOUR_IN_MS) * rectHeightWithoutBorder) + 1}px`,
    ...((typeof style === "function" ? style(event) : style) ?? {}),
  };

  const studio = studios[event.studioIndex];
  if (!studio) {
    alert("不正な予約データが入っています。");
    return {};
  }
//  const margin = rect.width * 0.03;
  const margin = 3;

  out.left = `${pos.x + margin}px`;
  out.width = `${rect.width - margin - margin}px`;
  out.zIndex = 99;
  out.backgroundColor = studio.brightColor;
  out.color = studio.color;

  return out;
}

const Events_unmemorized = ({
  processedEvents,
  eventSizeRef,
  headerRef,
  isWeekly,
  weekStart,
  selected,
  studioIndex,
  offsetHours,
}: EventProps) => {
  return (
    <>
      {processedEvents
        .filter((evt) =>
          isWeekly
            ? evt.studioIndex === studioIndex &&
              DATE_UTILS.is_within_week(weekStart, evt.from)
            : DATE_UTILS.compare_dates(evt.from, selected)
        )
        .map((evt) => (
          <div
            key={`${evt.studioIndex}_${evt.to.getTime()}_${evt.from.getTime()}`}
            role="presentation"
            className="event"
            style={styles_from_event(evt, eventSizeRef, headerRef, isWeekly, offsetHours)}
            aria-label={`Event with title ${evt.name}`}
          >
            <div className="time">
              {evt.rangeLabel}
            </div>
            <div className="title">{evt.name}</div>
          </div>
        ))}
    </>
  );
};
const Events = React.memo(
  Events_unmemorized,
  (prev: EventProps, next: EventProps): boolean => {
    const ev_prev = prev.eventSizeRef.current.getBoundingClientRect();
    const ev_next = next.eventSizeRef.current.getBoundingClientRect();

    const head_prev = prev.headerRef.current.getBoundingClientRect();
    const head_next = next.headerRef.current.getBoundingClientRect();

    return (
      prev.processedEvents.length === next.processedEvents.length &&
      prev.isWeekly === next.isWeekly &&
      prev.weekStart === next.weekStart &&
      prev.selected === next.selected &&
      prev.studioIndex === next.studioIndex &&
      JSON.stringify(ev_prev) === JSON.stringify(ev_next) &&
      JSON.stringify(head_prev) === JSON.stringify(head_next) &&
      prev.hasResized === next.hasResized
    );
  }
);

const Scheduler = ({
  events,

  selected,
  setSelected,

  studioIndex,
  offsetHours,

  style,

  isMobile,
}: SchedulerProps) => {
  const isWeekly = studioIndex !== null;

  const style_fixed: SchedulerStyles = {
    container: (style ?? {}).container ?? {},
    head: (style ?? {}).head ?? {},
    body: (style ?? {}).body ?? {},
  };

  const [weekStart, setWeekStart] = useState(
    DATE_UTILS.first_of_week(selected)
  );
  const processedEvents = useMemo(
    () => process_events(events, weekStart),
    [events, weekStart]
  );
  const [hasResized, setHasResized] = useState<number>(0);

  const eventSizeRef = useRef() as React.MutableRefObject<HTMLTableCellElement>;
  const headerRef = useRef() as React.MutableRefObject<HTMLTableCellElement>;

  useEffect(() => {
    if (!eventSizeRef.current) return;

    function resize() {
      setHasResized(Math.random());
    }

    resize();
    window.addEventListener("resize", resize);

    const resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(eventSizeRef.current);

    return () => {
      window.removeEventListener("resize", resize);
      resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => setWeekStart(DATE_UTILS.first_of_week(selected)), [selected]);

  const column_headers = isWeekly
    ? DATE_UTILS.SHORT_DAYS_OF_WEEK.map((day, ind) => {
        const tmp = DATE_UTILS.walk_day(weekStart, ind);
        return (
          <th
            key={day}
            className={
              DATE_UTILS.compare_dates(DATE_UTILS.TODAY, tmp) ? "today" : ""
            }
          >
            <div>{day}</div>
            <div>{tmp.getDate()}</div>
          </th>
        );
      })
    : studios.map((studio, ind) => (
        <th key={ind}>
          <div style={{ color: studio.color, fontWeight: 700 }}>
            {isMobile && studio.shortName ? studio.shortName : studio.name }
          </div>
        </th>
      ));

  const column_dummies = column_headers.map((_, i) => (
    <td key={i} ref={i === 0 ? eventSizeRef : null} />
  ));

  return (
    <div
      className="react-simple-scheduler"
      style={style_fixed.container}
      role="complementary"
      aria-label="Calendar"
    >
      <div className="head" style={style_fixed.head}>
        <h1>
          {isWeekly ? (
            <DateFormatter date={weekStart} fmt="Y年 W" />
          ) : (
            <DateFormatter date={selected} fmt="Y/n/d(x)" />
          )}
        </h1>
        <div className="head_right">
          <button
            type="button"
            className="chevron"
            onClick={() =>
              setSelected(DATE_UTILS.walk_day(selected, isWeekly ? -7 : -1))
            }
            aria-label="View previous week"
          >
            <LessThanSymbol />
          </button>
          <button
            type="button"
            className="today"
            onClick={() => setSelected(DATE_UTILS.TODAY)}
            aria-label="View current week"
          >
            今日
          </button>
          <button
            type="button"
            className="chevron flipped"
            onClick={() =>
              setSelected(DATE_UTILS.walk_day(selected, isWeekly ? 7 : 1))
            }
            aria-label="View next week"
          >
            <MoreThanSymbol />
          </button>
        </div>
        <div className="counterweight" />
      </div>

      <div
        className="body"
        style={style_fixed.body}
        tabIndex={0}
      >
        <table
          role="presentation"
          className="schedule"
          cellPadding={0}
          cellSpacing={0}
        >
          <thead>
            <tr>
              <th ref={headerRef} className="time">
                &nbsp;
              </th>
              {column_headers}
            </tr>
          </thead>
          <tbody>
            {[...Array(24 - offsetHours).keys()].map((i) => (
              <tr key={i}>
                <td className="time">{`${i + offsetHours}:00`}</td>
                {column_dummies}
              </tr>
            ))}
          </tbody>
        </table>
        <br />

        <Events
          processedEvents={processedEvents}
          eventSizeRef={eventSizeRef}
          headerRef={headerRef}
          hasResized={hasResized}
          isWeekly={isWeekly}
          weekStart={weekStart}
          selected={selected}
          studioIndex={studioIndex}
          offsetHours={offsetHours}
        />
      </div>
    </div>
  );
};

export default Scheduler;
