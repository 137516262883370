import { SchedulerExistingEvent } from "./types";
import studios from "./studios";

export const getReservations: () => Promise<void | Array<SchedulerExistingEvent>> =
  async () => {
    return await fetch("http://lensman.jp/availability/all.php")
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter((item: any) => {
          if (item["studio"] <= 0 || item["studio"] > studios.length) {
            // 不正なスタジオ番号の場合、そのデータをフィルターする
            // 1-origin
            return false;
          }
          return true;
        });
        return filteredData.map((item: any) => {
          const source_date = item["date"]; // 20221030
          const date =
            source_date.substring(0, 4) +
            "-" +
            source_date.substring(4, 6) +
            "-" +
            source_date.substring(6, 8);

          // このあとの丸めの影響を受けないように、ここでラベルを作る
          const rangeLabel = `${item.from} - ${item.to}`;

          let [fromHour, fromMinutes] = item.from.split(":");
          let fromSeconds = "00";
          let [toHour, toMinutes] = item.to.split(":");
          let toSeconds = "00";

          // 22:00-6:00とかそういう日付が入ることがあったとき
          if (+fromHour > +toHour) {
            toHour = (toHour + 24).toString();
          }

          // 8:00-24:00に丸める
          if (+fromHour < 8) { fromHour = "08"; fromMinutes = "00"; }
          if (+toHour >= 24) { toHour = "23"; toMinutes = "59"; toSeconds = "59"; }

          const fromStr = `${date}T${fromHour}:${fromMinutes}:${fromSeconds}`;
          const toStr = `${date}T${toHour}:${toMinutes}:${toSeconds}`;

          const from = new Date(fromStr);
          const to = new Date(toStr);

          return {
            from,
            to,
            rangeLabel,
            studioIndex: item["studio"] - 1, // 1 origin in source, but 0 origin here
            name: item["status"],
          };
        });
      });
  };
