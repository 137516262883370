import { SchedulerExistingEvent } from "./types";

type GlobalStateType = {
  events: Array<SchedulerExistingEvent> | null;
  selectedDate: Date;
};

export class GlobalState extends EventTarget {
  private state: GlobalStateType;

  constructor() {
    super();
    this.state = {
      events: null,
      selectedDate: new Date(),
    };
  }

  getEvents(): Array<SchedulerExistingEvent> | null {
    return this.state.events;
  }

  setEvents(value: Array<SchedulerExistingEvent>): this {
    this.state.events = value;
    this.dispatchEvent(
      new CustomEvent("set", {
        detail: {
          type: "set",
          key: "events",
          value,
        },
      })
    );
    return this;
  }

  getSelectedDate(): Date {
    return this.state.selectedDate;
  }

  setSelectedDate(newDate: Date): this {
    this.state.selectedDate = newDate;
    this.dispatchEvent(
      new CustomEvent("set", {
        detail: {
          type: "set",
          key: "selectedDate",
          value: newDate,
        },
      })
    );
    return this;
  }

  subscribe(onStoreChange: () => void): () => void {
    this.addEventListener("set", onStoreChange);
    return () => {
      this.removeEventListener("set", onStoreChange);
    };
  }
}
