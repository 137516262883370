/**
 * Date utility functions and constants
 */
import { DateRange } from "./types";

const TODAY = new Date();

const SATURDAY = 6;

const SHORT_DAYS_OF_WEEK = ["日", "月", "火", "水", "木", "金", "土"];
const LONG_DAYS_OF_WEEK = [
  "日曜日",
  "月曜日",
  "火曜日",
  "水曜日",
  "木曜日",
  "金曜日",
  "土曜日",
];

const MONTHS = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];

const MINUTE_IN_MS = 60 * 1000;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

const walk_month = (date: Date, amt: number = 1) => {
  const out = new Date(date);
  out.setMonth(out.getMonth() + amt);
  return out;
};
const walk_day = (date: Date, amt: number = 1) => {
  const out = new Date(date);
  out.setDate(out.getDate() + amt);
  return out;
};
const walk_hour = (date: Date, amt: number = 1) => {
  const out = new Date(date);
  out.setHours(out.getHours() + amt);
  return out;
};

const set_hour = (hr: number) => {
  const out = new Date(TODAY);
  out.setHours(hr);
  return out;
};

const first_of_month = (date: Date) => {
  const out = new Date(date);
  out.setDate(1);
  return out;
};
const first_of_week = (date: Date) => {
  const out = new Date(date);
  out.setDate(out.getDate() - out.getDay());
  return out;
};
const last_of_week = (date: Date) => {
  const out = new Date(date);
  out.setDate(out.getDate() + 6);
  return out;
};

const is_within_week = (wk: Date, day: Date, ignore_year: boolean = false) => {
  const first = new Date(wk);
  first.setDate(first.getDate() - first.getDay());
  first.setHours(0, 0, 0);

  const last = new Date(first);
  last.setDate(last.getDate() + 6);
  last.setHours(23, 59, 59);

  if (ignore_year) {
    first.setFullYear(day.getFullYear());
    last.setFullYear(day.getFullYear());
  }

  return day >= first && day <= last;
};

const compare_dates = (a: Date, b: Date) =>
  a.getDate() === b.getDate() &&
  a.getMonth() === b.getMonth() &&
  a.getFullYear() === b.getFullYear();
const compare_times = (a: Date, b: Date) =>
  a.getHours() === b.getHours() &&
  a.getMinutes() === b.getMinutes() &&
  a.getSeconds() === b.getSeconds();

const difference = (a: Date, b: Date) =>
  (a.getHours() - b.getHours()) * HOUR_IN_MS +
  (a.getMinutes() - b.getMinutes()) * MINUTE_IN_MS;
const difference_days = (a: Date, b: Date) =>
  Math.floor((b.getTime() - a.getTime()) / DAY_IN_MS);

const dates_overlap = (a: DateRange, b: DateRange) =>
  a.from <= b.to && a.to >= b.from;
const dates_overlap_exclusive = (a: DateRange, b: DateRange) =>
  a.from < b.to && a.to > b.from;

const copy_ymd = (date: Date, ymd: Date, ignore_year: boolean = false) => {
  const tmp = new Date(ymd);
  const cpy = new Date(date);
  if (!ignore_year) cpy.setFullYear(tmp.getFullYear());
  cpy.setMonth(tmp.getMonth());
  cpy.setDate(tmp.getDate());
  return cpy;
};
const copy_time = (date: Date, time: Date) => {
  const tmp = new Date(time);
  const cpy = new Date(date);
  cpy.setHours(tmp.getHours());
  cpy.setMinutes(tmp.getMinutes());
  return cpy;
};

export default {
  walk_month,
  walk_day,
  walk_hour,

  set_hour,

  first_of_month,
  first_of_week,
  last_of_week,

  is_within_week,

  compare_dates,
  compare_times,

  difference,
  difference_days,

  dates_overlap,
  dates_overlap_exclusive,

  copy_ymd,
  copy_time,

  TODAY,

  SATURDAY,
  SHORT_DAYS_OF_WEEK,
  LONG_DAYS_OF_WEEK,
  MONTHS,

  DAY_IN_MS,
  HOUR_IN_MS,
  MINUTE_IN_MS,
};
